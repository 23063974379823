import { gql } from 'graphql-request';

export const getDownloadCenterJob = gql`
  query getDownloadCenterJob($input: GetDownloadCenterJobInput!) {
    getDownloadCenterJob(input: $input) {
      id
      created
      downloadType
      modified
      result {
        link
        createdAt
        expiresAt
        headers {
          name
          value
        }
      }
      status
    }
  }
`;
