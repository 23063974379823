import React from 'react';
import { Badge } from '@material-ui/core';
import {
  PopoutFilter,
  ChecklistChangeEvent,
  ChecklistOption,
  ChecklistOptionGroup,
  Button,
} from '@esub-engineering/react-component-library';
import SortIcon from '@material-ui/icons/Sort';
import _get from 'lodash/get';
import { useSearchOptions } from '../../hooks';
import useStyles from './styles';
import { SearchOptionsProps } from './types';

/**
 * Manage filter and sort options along with their selections utilizing the SearchOption provider and hooks
 */
export const SearchOptions = React.memo(
  ({
    buttonProps,
    sortOptions = [],
    filterOptions = [],
    onApply = () => {},
    onClear = () => {},
    persistOnApply = true,
    kicker = undefined,
    panelWidth = undefined,
    children,
  }: SearchOptionsProps) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState<boolean>(false);

    const {
      sortByKey,
      addFilterValue,
      replaceFilterValue,
      removeFilterValue,
      tempSortValues,
      tempFilterValues,
      filterValues,
      clear,
      persist,
    } = useSearchOptions();

    const sortChecklistOptions: ChecklistOption<{}>[] = React.useMemo(
      () =>
        sortOptions.map((option) => ({
          ...option,
          selected:
            option.key === tempSortValues?.sortBy && option.value === tempSortValues.direction,
        })),
      [sortOptions, tempSortValues]
    );

    const includesFilterValue = React.useCallback(
      (arr: Array<string | boolean>, value: string | boolean) => arr.includes(value),
      []
    );

    const filterChecklistOptions: ChecklistOptionGroup<{}>[] = React.useMemo(
      () =>
        filterOptions.map((group) => ({
          ...group,
          options: group.options.map((option) => ({
            ...option,
            selected:
              tempFilterValues &&
              includesFilterValue(
                _get((tempFilterValues as unknown) as (string | boolean)[], option.key, []),
                option.value
              ),
          })),
        })),
      [filterOptions, tempFilterValues, includesFilterValue]
    );

    const filterCount = React.useMemo(
      () => (!filterValues ? 0 : Object.keys(filterValues).length),
      [filterValues]
    );

    const handleOpen = React.useCallback(() => {
      setOpen(true);
    }, []);

    const handleClose = React.useCallback(() => {
      setOpen(false);
    }, []);

    const handleSort: ChecklistChangeEvent<{}> = (event, { option }) => {
      sortByKey(option.key, option.value as 'DESC' | 'ASC');
    };

    const handleFilter: ChecklistChangeEvent<{}> = (event, { group, option }) => {
      // if only a single value is allowed we will replace it
      if (!group.multiple) {
        replaceFilterValue(option.key, option.value);
        return;
      }

      // otherwise toggle the value if based on check state
      if (event.target.checked) {
        addFilterValue(option.key, option.value);
      } else {
        removeFilterValue(option.key, option.value);
      }
    };

    const handleClear = React.useCallback(() => {
      clear();
      onClear({ open, sortOptions, filterOptions });
    }, [clear, filterOptions, onClear, open, sortOptions]);

    const handleApply = React.useCallback(() => {
      persist(persistOnApply);
      setOpen(false);

      // callback
      onApply({ open, sortOptions, filterOptions });
    }, [persist, persistOnApply, onApply, open, sortOptions, filterOptions]);

    return (
      <>
        <Button
          {...buttonProps}
          className={classes.button}
          color="primary"
          onClick={handleOpen}
          title="filters"
        >
          <Badge badgeContent={filterCount} color="primary">
            <SortIcon />
          </Badge>
        </Button>
        <PopoutFilter
          open={open}
          onClear={handleClear}
          onApply={handleApply}
          onClose={handleClose}
          onSortChange={handleSort}
          onFilterChange={handleFilter}
          filterOptions={filterChecklistOptions}
          sortOptions={sortChecklistOptions}
          kicker={kicker}
          width={panelWidth}
        >
          {children}
        </PopoutFilter>
      </>
    );
  }
);
